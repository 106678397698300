import styled from "styled-components"

import { Section as SectionComponent } from "../"
import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Section = styled(SectionComponent)`
  background-color: var(--color-white);
  padding: 0 0 100px;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding: 0 0 60px;
  }

  .s-content {
    margin-top: 50px;
  }
`

export const WrapperPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .card {
    width: calc(33.333% - 20px);
    @media ${breakpoints.lessThan(sizes.desktop)} {
      width: calc(50% - 15px);
    }
    @media ${breakpoints.lessThan(sizes.tablet)} {
      width: 100%;
    }
  }
`
