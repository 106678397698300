import styled from "styled-components"

export const Card = styled.article`
  position: relative;
  background-color: var(--color-grayscale3);
  border-radius: 4px;
  overflow: hidden;

  .c-figure {
    overflow: hidden;
    width: 100%;
    height: 230px;

    .img-wrapper {
      width: 100%;
      height: 100%;
      transition: transform ease-in-out 0.2s;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .c-content {
    position: relative;
    z-index: 10;
    padding: 20px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -30px 8px 8px;
    border-radius: 4px;
    background-color: var(--color-white);

    .title {
      font-weight: bold;
      color: var(--color-primary);
      font-size: 1.2rem;
      line-height: 1.1;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .paragraph {
      margin-top: 12px;
      margin-bottom: auto;
      color: var(--color-grayscale5);
      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .link {
      font-size: 0.8rem;
      font-weight: normal;
      display: flex;
      align-items: center;
      color: var(--color-primary);
      text-transform: uppercase;
      transition: transform ease-in-out 0.2s, color linear 0.2s;

      .icon {
        margin-left: 5px;
        font-size: 1.5rem;
        padding-bottom: 2px;
      }
    }
  }

  .link-full {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover {
    .c-figure .img-wrapper {
      transform: scale(1.08);
    }

    .c-content {
      .link {
        transform: translateX(10px);
        color: var(--color-secondary);
      }
    }
  }
`
