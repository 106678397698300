import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionArticlesIntern from "../components/Section/SectionArticlesIntern"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const ArticlePage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout>
      <Seo title="Artigos" />
      <SectionArticlesIntern data={data.allStrapiArticle.edges} />
      <WppButtonFloat />
    </Layout>
  )
}

const query = graphql`
  query {
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          description
          slug
          content
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  }
`

export default ArticlePage
